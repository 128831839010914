import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"

import "./slides.css"

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  require("../../../../../static/plugins/fullpage.fadingEffect.min.js")
  require("../../../../../static/plugins/fullpage.scrollHorizontally.min.js")
}

const Slides = ({ children, onSlideLeaveCallback = () => {} }) => {
  return (
    <ReactFullpage
      pluginWrapper={pluginWrapper}
      scrollHorizontally
      licenseKey="F7C8C209-27E347B6-B8D32569-EADB4BA4"
      scrollHorizontallyKey="cHVwcHlsb3ZlLmFnZW5jeV9TU0FjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDUyYjI="
      offsetSectionsKey="cHVwcHlsb3ZlLmFnZW5jeV9yaEliMlptYzJWMFUyVmpkR2x2Ym5NPTg0Zg=="
      fadingEffectKey="cHVwcHlsb3ZlLmFnZW5jeV80eFlabUZrYVc1blJXWm1aV04wZkls"
      touchSensitivity={15}
      scrollingSpeed={700}
      controlArrows={false}
      loopHorizontal={false}
      fadingEffect
      onSlideLeave={(section, origin, destination) => {
        onSlideLeaveCallback(destination.index)
      }}
      render={() => {
        return (
          <ul className="fullpage-wrapper slides">
            <div className="section">{children}</div>
          </ul>
        )
      }}
    />
  )
}

export default Slides
