import React from "react"
import classnames from "classnames"

/* Import Local Styles */
import "./breadcrumbs.css"

// bread crumbs takes a length ( how many breadcrumbs )
// and a function which takes the index to determine behavior

const Breadcrumb = ({
  index,
  activeBreadcrumb,
  setActiveBreadcrumb,
  handleClick,
}) => (
  <button
    className="breadcrumb-container"
    onClick={() => {
      setActiveBreadcrumb(index)
      handleClick(index)
    }}
  >
    <div
      className={classnames("breadcrumb", {
        active: activeBreadcrumb === index,
      })}
    />
  </button>
)

const Breadcrumbs = ({
  length = 5,
  handleClick = () => {},
  activeBreadcrumb = 0,
  setActiveBreadcrumb = () => {},
}) => {
  return (
    <div className="breadcrumbs">
      {Array.apply(null, Array(length)).map((item, index) => {
        return (
          <Breadcrumb
            key={index}
            index={index}
            activeBreadcrumb={activeBreadcrumb}
            setActiveBreadcrumb={setActiveBreadcrumb}
            handleClick={handleClick}
          />
        )
      })}
    </div>
  )
}

export default Breadcrumbs
