import React from "react"
import classnames from "classnames"
/* Import Global Components */
import LefPointer from "~components/icon/normal/leftPointer"

/* Import Local Styles */
import "./previous-slide-button.css"

const PreviousSlideButton = ({ active }) => {
  const goToPreviousSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideLeft()
    }
  }

  return (
    <div className={classnames("previous-slide-button", { active: active })}>
      <button onClick={goToPreviousSlide}>
        <LefPointer />
      </button>
    </div>
  )
}

export default PreviousSlideButton
