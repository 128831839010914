import React, { useState, useEffect, useContext, useRef } from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { useMount } from "react-use"
import { Howl, Howler } from "howler"
import classnames from "classnames"

/* Import Global Context */
import VoiceContext from "~context/voice"
import FirstVisitContext from "~context/firstVisit"
import OnboardingContext from "~context/onboarding"

/* Import Global Components */
import Page from "~components/page"
import Breadcrumbs from "~components/breadcrumbs"
import AudioToggle from "~components/audioToggle"
import Restart from "~components/icon/normal/restart"

/* Import Local Components */
import Slides from "./components/slides"
import NextSlideButton from "./components/nextSlideButton/nextSlideButton"
import PreviousSlideButton from "./components/previousSlideButton/previousSlideButton"
import ExploreWork from "./components/exploreWork"

/* Import Slides */
import ColorsSlide from "./slides/colors"
import VoicesSlide from "./slides/voices"
import TextSlide from "./slides/text"
import LinksSlide from "./slides/links"

/* Import Local Styles */
import "./welcome.css"

/* Import Audio Settings Files */
import CowboySettings from "~assets/audio/cowboy/output.json"
import CowboyAudioWebmSrc from "~assets/audio/cowboy/output.webm"
import CowboyAudioMp3Src from "~assets/audio/cowboy/output.mp3"
import RelaxedSettings from "~assets/audio/relaxed/output.json"
import RelaxedAudioWebmSrc from "~assets/audio/relaxed/output.webm"
import RelaxedAudioMp3Src from "~assets/audio/relaxed/output.mp3"
import GrandmaSettings from "~assets/audio/grandma/output.json"
import GrandmaAudioWebmSrc from "~assets/audio/grandma/output.webm"
import GrandmaAudioMp3Src from "~assets/audio/grandma/output.mp3"

const Index = () => {
  const [voice, setVoice] = useContext(VoiceContext)
  const [, setIsFirstVisit] = useContext(FirstVisitContext)
  const [, setIsOnboardingOpen] = useContext(OnboardingContext)
  const [activeBreadcrumb, setActiveBreadcrumb] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  // const [selectedSound, setSelectedSound] = useState("cowboy")
  const cowboyHowl = useRef()
  const relaxedHowl = useRef()
  const grandmaHowl = useRef()
  const howls = useRef({
    cowboy: cowboyHowl,
    asmr: relaxedHowl,
    auntRuthie: grandmaHowl,
  })
  const playingSounds = useRef([])

  // https://github.com/goldfire/howler.js/issues/1294
  // function to check is audio is currently locked by browser
  const isAudioLocked = () => {
    return new Promise(resolve => {
      const checkHTML5Audio = async () => {
        const audio = new Audio()
        try {
          audio.play()
          resolve(false)
        } catch (err) {
          resolve(true)
        }
      }
      try {
        const context = new (window.AudioContext || window.webkitAudioContext)()
        resolve(context.state === "suspended")
      } catch (e) {
        checkHTML5Audio()
      }
    })
  }

  // https://github.com/goldfire/howler.js/issues/1294
  // set up event listeners for ui purposes
  useMount(async () => {
    const userGestureEvents = [
      "click",
      "contextmenu",
      "auxclick",
      "dblclick",
      "mousedown",
      "mouseup",
      "pointerup",
      "touchend",
      "keydown",
      "keyup",
    ]
    const unlockAudio = () => {
      setIsMuted(false)
      userGestureEvents.forEach(eventName => {
        document.removeEventListener(eventName, unlockAudio)
      })
    }
    if (await isAudioLocked()) {
      userGestureEvents.forEach(eventName => {
        document.addEventListener(eventName, unlockAudio)
      })
    }
  })

  // mute howler if muted
  useEffect(() => {
    if (isMuted) {
      Howler.mute(true)
    } else {
      Howler.mute(false)
    }
  }, [isMuted])

  useMount(() => {
    //
    setIsFirstVisit(false)
    // set up audio files
    // cowboy audio files
    cowboyHowl.current = new Howl({
      src: [CowboyAudioWebmSrc, CowboyAudioMp3Src],
      sprite: CowboySettings.sprite,
    })
    // relaxed audio files
    relaxedHowl.current = new Howl({
      src: [RelaxedAudioWebmSrc, RelaxedAudioMp3Src],
      sprite: RelaxedSettings.sprite,
    })
    // relaxed audio files
    grandmaHowl.current = new Howl({
      src: [GrandmaAudioWebmSrc, GrandmaAudioMp3Src],
      sprite: GrandmaSettings.sprite,
    })
    // check if can play
    if (Howler.ctx.state === "running") {
      setIsMuted(false)
    }
    // when an audio file is done playing, remove from playing sounds array
    cowboyHowl.current.on("end", idToRemove => {
      playingSounds.current = playingSounds.current.filter(id => {
        return id !== idToRemove
      })
    })
    relaxedHowl.current.on("end", idToRemove => {
      playingSounds.current = playingSounds.current.filter(id => {
        return id !== idToRemove
      })
    })
    grandmaHowl.current.on("end", idToRemove => {
      playingSounds.current = playingSounds.current.filter(id => {
        return id !== idToRemove
      })
    })
  })

  // handle per-slide audio
  useEffect(() => {
    if (activeBreadcrumb && voice) {
      // fade out any currently playing sounds
      playingSounds.current.forEach(id => {
        howls.current[voice].current.fade(1, 0, 300, id)
      })
      // play sound for corresponding slide
      if (activeBreadcrumb === 2) {
        const id = howls.current[voice].current.play("1")
        playingSounds.current.push(id)
      }
      if (activeBreadcrumb === 3) {
        const id = howls.current[voice].current.play("2")
        playingSounds.current.push(id)
      }
      if (activeBreadcrumb === 4) {
        const id = howls.current[voice].current.play("3")
        playingSounds.current.push(id)
      }
    }
  }, [activeBreadcrumb, voice])

  // set onboarding
  useEffect(() => {
    if (activeBreadcrumb === 0 || activeBreadcrumb === 1) {
      setIsOnboardingOpen(true)
    } else {
      setIsOnboardingOpen(false)
    }
  }, [activeBreadcrumb, setIsOnboardingOpen])

  const returnToFirstSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveTo(1, 0)
    }
  }

  const fadeAudio = () => {
    playingSounds.current.forEach(id => {
      howls.current[voice].current.fade(1, 0, 300, id)
    })
  }

  return (
    <Page className="index">
      <Breadcrumbs
        length={6}
        activeBreadcrumb={activeBreadcrumb}
        setActiveBreadcrumb={setActiveBreadcrumb}
        handleClick={index => {
          if (window && window.fullpage_api) {
            window.fullpage_api.moveTo(1, index)
          }
        }}
      />
      <NextSlideButton active={activeBreadcrumb !== 5} />
      <PreviousSlideButton active={activeBreadcrumb !== 0} />
      <ExploreWork slide={activeBreadcrumb} fadeAudio={fadeAudio} />
      <div className="footer">
        <div className="controls">
          <SwitchTransition className="transition-group">
            <CSSTransition
              key={activeBreadcrumb === 5}
              classNames="transition"
              timeout={250}
            >
              {activeBreadcrumb === 5 ? (
                <button
                  className={classnames("onboarding-footer-controls", {
                    wiggle: activeBreadcrumb === 5,
                  })}
                  onClick={returnToFirstSlide}
                >
                  <Restart />
                </button>
              ) : (
                <AudioToggle
                  className="onboarding-footer-controls"
                  muted={isMuted}
                  handleClick={() => {
                    setIsMuted(!isMuted)
                  }}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <Slides
        onSlideLeaveCallback={index => {
          setActiveBreadcrumb(index)
        }}
      >
        <VoicesSlide setVoice={setVoice} active={activeBreadcrumb === 0} />
        <ColorsSlide active={activeBreadcrumb === 1} />
        <TextSlide
          active={activeBreadcrumb === 2}
          copy="We build digital and physical experiences for brands who want to be
          different."
        />
        <TextSlide
          active={activeBreadcrumb === 3}
          copy="Puppy love is a collective of creatives, artists, designers,
          photographers, engineers, painters, game programmers and more..."
          delay={10}
        />
        <TextSlide
          active={activeBreadcrumb === 4}
          copy="For the past decade, our talent has created some of the most
          forward-thinking projects in the experiential and digital space for
          brands around the world."
          delay={5}
        />
        <LinksSlide active={activeBreadcrumb === 5} />
      </Slides>
    </Page>
  )
}
export default Index
