import React from "react"
import classnames from "classnames"

/* Import Global Components */
import AudioOnSvg from "~components/icon/normal/audioOn"
import AudioOffSvg from "~components/icon/normal/audioOff"

/* Import Local Styles */
import "./audio-toggle.css"

const AudioToggle = ({ muted = true, handleClick = () => {} }) => {
  return muted ? (
    <button className={classnames("audio-toggle")} onClick={handleClick}>
      <AudioOffSvg />
    </button>
  ) : (
    <button className={classnames("audio-toggle")} onClick={handleClick}>
      <AudioOnSvg />
    </button>
  )
}

export default AudioToggle
