import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

/* Import Global Components */
import PlusInCircleSvg from "~components/icon/normal/plusInCircle"

/* Import Local Styles */
import "./explore-work.css"

const ExploreWork = ({ slide, fadeAudio }) => {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    // set visibility
    if (slide >= 2 && slide < 5) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [slide])

  return (
    <div
      className={classnames("explore-work-container", {
        "is-visible": isVisible,
      })}
    >
      <Link
        to="/works"
        className={classnames("explore-works-link", {
          wiggle: isVisible,
        })}
        onClick={fadeAudio}
      >
        <span>Explore Work </span>
        <span className="icon-container">
          <PlusInCircleSvg />
        </span>
      </Link>
    </div>
  )
}

export default ExploreWork
