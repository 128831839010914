import React, { useState, useEffect } from "react"
import classnames from "classnames"

/* Import Global Components */
import RightHandIcon from "~components/icon/normal/rightHand"
import ColorPicker from "~components/colorPicker"
import FadeInChildElements from "~components/animations/fadeInChildElements"
import FadeInCharactersSequentially from "~components/animations/fadeInCharactersSequentially"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./colors.css"

const ColorsSlide = ({ active }) => {
  const [slideVisited, setSlideVisited] = useState(false)
  const [textAnimationComplete, setTextAnimationComplete] = useState(false)

  useEffect(() => {
    if (active) {
      setSlideVisited(true)
    }
  }, [active])

  return (
    <Slide title="colors">
      <div className="colors-container">
        <FadeInChildElements start={slideVisited}>
          <div>
            <div className="copy">
              <span
                className={classnames("icon-container", {
                  wiggle: slideVisited,
                })}
              >
                <RightHandIcon />
              </span>
              <FadeInCharactersSequentially
                start={slideVisited}
                copy={"Pick a Background"}
                callback={() => {
                  setTextAnimationComplete(true)
                }}
              />
            </div>
            <FadeInChildElements start={textAnimationComplete}>
              <div>
                <ColorPicker
                  newColor={"white"}
                  newBackgroundColor={"black"}
                  newMenuColor={"black"}
                  newMenuBackgroundColor={"purple"}
                />
                <ColorPicker
                  newColor={"black"}
                  newBackgroundColor={"yellow"}
                  newMenuColor={"black"}
                  newMenuBackgroundColor={"purple"}
                />
                <ColorPicker
                  newColor={"black"}
                  newBackgroundColor={"purple"}
                  newMenuColor={"white"}
                  newMenuBackgroundColor={"black"}
                />
                <ColorPicker
                  newColor={"black"}
                  newBackgroundColor={"white"}
                  newMenuColor={"black"}
                  newMenuBackgroundColor={"purple"}
                />
              </div>
            </FadeInChildElements>
          </div>
        </FadeInChildElements>
      </div>
    </Slide>
  )
}

export default ColorsSlide
