/* 
    This component is used like so: 
        
        <ApplyStyleSequentially style={"opacity": "1"}>
            <p>Component</p>
            <div>Component</div>
        </ApplyStyleSequentially>

    It will apply styles to each child of ApplyStyleSequentially in order to style them sequentially, and can use the 
    `delay` and `transitionDuration` props to control the timing.
                
*/

import {
  useState,
  useEffect,
  isValidElement,
  cloneElement,
  Children,
} from "react"

const ApplyStyleToChildrenSequentially = ({
  children,
  delay = 250,
  transitionDuration = 400,
  initialStyle = {},
  activeStyle = {},
  start = false,
}) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0)

  useEffect(() => {
    if (!start) {
      setMaxIsVisible(0)
    }
  }, [start])

  useEffect(() => {
    if (!start) return
    let count = Children.count(children)

    if (count === maxIsVisible) {
      return () => clearTimeout(timeout)
    }

    // Move maxIsVisible toward count
    const increment = start && count > maxIsVisible ? 1 : -1
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment)
    }, delay)
    return () => clearTimeout(timeout)
  }, [children, delay, maxIsVisible, transitionDuration, start])

  return Children.map(children, (child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        style:
          maxIsVisible > i
            ? { ...activeStyle, transition: `all ${transitionDuration}ms` }
            : {
                ...initialStyle,
                transition: `all ${transitionDuration}ms`,
              },
      })
    }
  })
}

export default ApplyStyleToChildrenSequentially
