import React from "react"

/* Import Local Styles */
import "../icon.css"

const AudioOnSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 41 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.21 23.984C30.6094 25.619 29.7804 27.032 28.723 28.233C27.6656 29.424 26.4644 30.3222 25.1278 30.9177V30.4837C26.5828 29.4038 27.6825 27.7789 28.4269 25.6291C29.1713 23.4793 29.552 21.1681 29.552 18.7156C29.552 16.374 29.1544 14.194 28.3677 12.1654C27.581 10.1367 26.4728 8.57235 25.0432 7.45205V7.01807C26.3713 7.61354 27.5641 8.47142 28.6299 9.58162C29.6958 10.7019 30.5417 12.0443 31.1677 13.5985C31.7936 15.1629 32.1151 16.8585 32.1151 18.7055C32.1151 20.5928 31.8106 22.349 31.21 23.984Z"
      fill="none"
    />
    <path
      d="M38.9753 25.6287C37.9937 27.7935 36.6387 29.6643 34.9105 31.2545C33.1823 32.8314 31.219 34.0207 29.0345 34.8091V34.2345C31.4126 32.8046 33.2099 30.6532 34.4266 27.8069C35.6433 24.9606 36.2654 21.9004 36.2654 18.6532C36.2654 15.553 35.6156 12.6666 34.3298 9.98065C33.044 7.29469 31.2328 5.22343 28.8962 3.74014V3.16553C31.0669 3.95394 33.0164 5.0898 34.7584 6.55973C36.5005 8.04302 37.8831 9.82029 38.9062 11.8782C39.9293 13.9495 40.4547 16.1944 40.4547 18.6399C40.4409 21.1387 39.957 23.4639 38.9753 25.6287Z"
      fill="none"
    />
    <path
      d="M18.7196 0.00313606C18.2201 0.004692 17.6678 0.228748 17.1636 0.731319L8.26518 10.7967H1.55595C0.69862 10.7967 0 11.4954 0 12.3527V24.8002C0 25.6576 0.69862 26.3562 1.55595 26.3562H8.26674L17.1154 36.3734C18.6713 37.9293 20.2273 37.1327 20.2273 35.0119V1.89672C20.2273 0.665969 19.5536 -0.00464363 18.7196 2.42044e-05V0.00313606Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default AudioOnSvg
