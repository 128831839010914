import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

/* Import Global Components */
import PlusInCircleSvg from "~components/icon/normal/plusInCircle"
import ApplyStyleToChildrenSequentially from "~components/animations/applyStylesToChildrenSequentially"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./links.css"

const LinksSlide = ({ active }) => {
  const [slideVisited, setSlideVisited] = useState(false)

  useEffect(() => {
    if (active) {
      setSlideVisited(true)
    }
  }, [active])

  return (
    <Slide title="links">
      <div className="links-container">
        <div>
          <ApplyStyleToChildrenSequentially
            delay={325}
            transitionDuration={520}
            initialStyle={{ opacity: 0, transform: "translate3d(0, 100px, 0)" }}
            activeStyle={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            start={slideVisited}
          >
            <Link
              to="/works"
              className={classnames("wiggle-1", {
                wiggle: slideVisited,
              })}
            >
              <span>Explore Work </span>
              <span>
                <PlusInCircleSvg />
              </span>
            </Link>
            <Link
              to="/about"
              className={classnames("wiggle-3", {
                wiggle: slideVisited,
              })}
            >
              <span>Learn More About P.L. </span>
              <span>
                <PlusInCircleSvg />
              </span>
            </Link>
            <Link
              to="/ip"
              className={classnames("wiggle-5", {
                wiggle: slideVisited,
              })}
            >
              <span>IP </span>
              <span>
                <PlusInCircleSvg />
              </span>
            </Link>
            <Link
              to="/contact"
              className={classnames("wiggle-7", {
                wiggle: slideVisited,
              })}
            >
              <span>Say Hello </span>
              <span>
                <PlusInCircleSvg />
              </span>
            </Link>
          </ApplyStyleToChildrenSequentially>
        </div>
      </div>
    </Slide>
  )
}

export default LinksSlide
