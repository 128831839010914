import React from "react"

/* Import Local Styles */
import "../icon.css"

const LeftPointerSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 22 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.84775 20.0464C4.9197 22.5672 7.45887 24.3847 9.45393 25.5257C11.449 26.6667 18.6357 30.5539 19.4406 30.8192C23.238 32.0398 21.481 36.9221 21.481 36.9221C21.481 36.9221 19.2025 40 17.8196 40C17.4115 39.2836 16.9467 38.5672 16.4366 37.8375C15.9265 37.1211 15.4391 36.3648 14.9744 35.5954C12.8206 32.5439 10.4288 29.6385 7.79893 26.8789C5.15774 24.1194 2.5619 21.8507 5.43911e-05 20.0464L1.84775 20.0464ZM0.0113917 20.0464C2.57323 18.1891 5.16908 15.8806 7.81028 13.1211C10.4515 10.3615 12.8433 7.45605 14.9857 4.40464C15.4505 3.68822 15.9379 2.94527 16.448 2.20232C16.9581 1.4461 17.8309 -1.2247e-07 17.8309 -1.2247e-07C17.8309 -1.2247e-07 21.3223 3.19735 21.3223 4.77612C21.3223 6.35489 20.8462 8.58375 19.9167 9.35323C17.0941 10.8922 11.449 13.3201 9.4426 14.461C7.44754 15.602 4.90837 17.4594 1.83642 20.0332L0.0113917 20.0332L0.0113917 20.0464Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default LeftPointerSvg
